@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*:not(.MuiChip-root, #grapesjs-react, #grapesjs-react *, .borderRadius, .borderRadius *) {
    border-radius: 0 !important;
    font-family: 'Roboto', sans-serif !important;
}

.ck-editor .ck-editor__main {
    min-height: 30vh;
    height: 30vh;
    resize: vertical;
    overflow: auto;
    transition: none;
}

@media screen and (max-width: 600px) {
    header .MuiIconButton-root {
        margin-right: 8px !important;
    }

    header span span {
        font-size: 0.8rem;
    }

    header > div > span {
        width: 80%;
    }
}

.ck-editor__main .ck-content {
    height: 100% !important;
}

*:not(
        .MuiTooltip-popper,
        .MuiAutocomplete-popper,
        .MuiDataGrid-virtualScroller,
        circle,
        animate,
        [class*='MuiPopper'],
        .MuiButtonBase-root,
        #grapesjs-react,
        #grapesjs-react *,
        .ck-editor .ck-editor__main,
        .noTransition,

    ) {
    transition: all 0.4s;
}

a .MuiMenuItem-root .MuiTypography-root,
a .MuiMenuItem-root .MuiListItemIcon-root,
a .MuiMenuItem-root .MuiSvgIcon-root {
    color: #016e80;
}

a .MuiMenuItem-root[class*='selected'] .MuiTypography-root,
a .MuiMenuItem-root[class*='selected'] .MuiListItemIcon-root,
a .MuiMenuItem-root[class*='selected'] .MuiSvgIcon-root {
    color: #fff;
}

.MuiTabs-flexContainer .MuiButtonBase-root {
    background-color: #016e80;
}

.MuiTabs-indicator {
    background-color: #f3ca12 !important;
}

.MuiTabs-flexContainer .MuiButtonBase-root[class*='selected'] {
    color: #f3ca12;
}

::-moz-selection {
    background: #f3ca12;
    color: #fff;
}

::selection {
    background: #f3ca12;
    color: #fff;
}

h2[class*='MuiDialogTitle-root'] {
    background-color: #016e80;
    color: #f3ca12;
}

.MuiDrawer-paper .MuiMenuItem-root {
    color: #fff;
}
