.mccNl {
    position: static;
    & .gjs-one-bg {
        background-color: #016e80 !important;
        color: #f3ca12 !important;
    }

    & .gjs-four-color {
        color: #f3ca12 !important;
    }

    & .gjs-pn-views-container {
        overflow: visible;
    }
}
